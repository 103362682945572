(<template>
  <section class="action-buttons">
    <div class="action-buttons__wrapper">
      <button v-if="config.showEditBtn"
              class="sk-btn sk-btn--default action-buttons__btn"
              @click="editAssignment">{{ $gettext('Edit') }}</button>
      <button v-if="config.showCopyBtn"
              class="sk-btn sk-btn--default action-buttons__btn"
              @click="copyAssignment">{{ $gettext('Copy') }}</button>
      <template v-if="config.showCancelBtn">
        <admin-only-note v-if="$isGodmode() && userIsInterpreter"
                         class="assignment-note__basic-wrapper action-buttons__btn-wrapper">
          <button class="sk-btn sk-btn--red action-buttons__btn"
                  @click="openCancelAssignmentModal">{{ $gettext('Cancel assignment') }}</button>
        </admin-only-note>
        <button v-else-if="userIsNotInterpreter"
                class="sk-btn sk-btn--red action-buttons__btn"
                @click="openCancelAssignmentModal">{{ $gettext('Cancel assignment') }}</button>
      </template>
      <button v-if="config.showReplacementBtn"
              class="sk-btn sk-btn--white action-buttons__btn"
              @click="openReplacementConfirmationModal">{{ $gettext('Create replacement assignment') }}</button>
    </div>
  </section>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import JobActionsPrototype from '@/prototypes/JobActionsPrototype';
  import AdminOnlyNote from '@/components/shared_components/AdminOnlyNote';

  export default {
    components: {
      'admin-only-note': AdminOnlyNote
    },
    extends: JobActionsPrototype,
    props: {
      config: {
        type: Object,
        required: true,
        default() {
          return {
            showEditBtn: false,
            showDeleteBtn: false,
            showReplacementBtn: false,
            showCopyBtn: false,
          };
        }
      }
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsInterpreter',
        'userIsNotInterpreter'
      ]),
      ...mapGetters('OneAssignmentStore', [
        'isSecretaryAssignment'
      ]),
      ...mapState('OneAssignmentStore', {
        assignmentObj: ({storeJobObj}) => storeJobObj || {},
        contractInfo: ({jobCallDetails}) => {
          return jobCallDetails?.contractInfo?.length ? jobCallDetails.contractInfo[0] : [];
        }
      }),
      jobStatus() { return this.assignmentObj.status || ''; },
      freeCancellationPeriod() { return this.assignmentObj.freeCancellationPeriod; },
      assignmentInfo() { return this.assignmentObj.info || {}; },
      assignmentRequirements() { return this.assignmentObj.assignmentRequirements || {}; },
      // statuses of an assignment
      assignmentIsPublished() { return this.jobStatus === 'published'; },
      assignmentIsApplied() { return this.jobStatus === 'applied'; },
      assignmentIsAccepted() { return this.jobStatus === 'accepted'; }
    },
    methods: {
      editAssignment() {
        let textContent = '';
        if (this.assignmentIsPublished) {
          return this.goToEditForm();
        } else if (this.assignmentIsApplied) {
          textContent = this.$gettext('If you edit this assignment we will informing all the interpreters about the changes. They may withdraw their offer because the changes don\'t suit them anymore.');
        } else if (this.assignmentIsAccepted) {
          textContent = this.$gettext('If you edit this assignment we will send the interpreter an e-mail, informing them about the changes. The changes may clash with another assignment or they may withdraw their offer because the changes don\'t suit them anymore.');
        }
        this.$store.commit('ModalStore/setModal', {
          component: 'confirm-edit',
          width: 410,
          data: {
            title: this.$gettext('Are you sure you want to edit this assignment?'),
            context: this,
            text: textContent
          }
        });
      },
      goToEditForm() {
        const routeName = this.isSecretaryAssignment
          ? 'BuyerEditSecretaryInterpretation'
          : 'BuyerEditInterpretation';

        this.$router.push({name: routeName, params: {id: this.$route.params.id}});
      },
      copyAssignment() {
        const routeName = this.isSecretaryAssignment
          ? 'BuyerPostSecretaryInterpretation'
          : 'BuyerPostInterpretation';
        const routeObj = {
          name: routeName,
          query: {
            copy: this.assignmentObj.id
          }
        };

        window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
      },
      openReplacementConfirmationModal() {
        const modalParagraphs = [
          this.$gettext('Clicking this button will do the following things:'),
          this.$gettext('1. This assignment will be cancelled'),
          this.$gettext('2. You will be taken to the booking page, with information copied from this assignment'),
          this.$gettext('3. You have to than make the replacement booking manually'),
          this.$gettext('Cancellation and then copying will take some time. Do not reload the page after clicking this button.'),
        ];
        const modalContent = modalParagraphs.map((p) => `<p class="action-buttons__confirmation-paragraph">${p}</p>`).join('');

        this.$store.commit('ModalStore/setModal', {
          component: 'confirmation-modal',
          width: 410,
          classes: ['sk-modal--new'],
          data: {
            title: this.$gettext('Cancel & make a new assignment'),
            text: modalContent,
            context: this,
            modalCallback: this.createReplacementAssignment,
            btnTexts: {
              actionBtnText: this.$gettext('Confirm'),
              cancelBtnText: this.$gettext('Cancel')
            }
          }
        });
      },
      createReplacementAssignment() {
        const jobId = this.$route.params.id;
        const routeObj = {
          name: 'BuyerPostInterpretation',
          query: {
            copy: jobId,
            replacement: true
          }
        };

        this.$store.commit('OneAssignmentStore/startAssignmentProgress');
        window.open(this.$router.resolve(this.$makeRoute(routeObj)).href, '_blank');
        this.$store.dispatch('OneAssignmentStore/sendCancelNotShown', jobId).then(() => {
          const promiseArr = [
            this.$store.dispatch('OneAssignmentStore/getJobById', jobId),
            this.$store.dispatch('OneAssignmentStore/getDiscussions', jobId)
          ];

          Promise.all(promiseArr).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          });
        }).catch((error) => {
          this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          this.$store.commit('ModalStore/setModal', {
            component: 'error-modal',
            data: {
              error
            }
          });
        });
      }
    }
  };
</script>

<style>
  .action-buttons__confirmation-paragraph:first-child {
    margin-bottom: 15px;
  }

  .action-buttons__confirmation-paragraph:last-child {
    margin-top: 15px;
  }
</style>

<style scoped>
  .action-buttons__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    margin: -10px;
  }

  .action-buttons__btn {
    width: auto;
    min-width: 110px;
    margin: 10px;
    padding: 0 15px;
  }

  .action-buttons__btn-wrapper {
    position: relative;
    margin: 10px;
  }

  .action-buttons__btn-wrapper .action-buttons__btn {
    margin: 0;
  }

  @media (max-width: 550px) {
    .action-buttons__btn {
      flex-grow: 1;
    }
  }
</style>
